import React, { Component, Fragment } from "react";
import "./Business.css";
import { formatedMoneyBRAFromFloat,
    getOptionsSelectCheckedFormated,
    getFullNameFisrtCharsUppercase,
    formatedDateTimeToUSA,
    formatedMoneyBRA,
    removeAccents,
    sortInAlphabeticalOrder
} from "../../auxiliary/generalFunctions";
import { withRouter } from "react-router-dom";
import { APPLICATION_NAME,
    business,
    HISTORIC_TYPE_ACTIVITY,
    modeBusinessDraggable,
    modeBusinessList,
    TRANSFER_TYPE_ACTIVITY
} from "../../core/constants";
import { connect } from "react-redux";
import { showModalMoreActions } from "../../store/actions/general";
import { getAllUsersLeads } from "../../store/actions/leads";
import { changeDealBusiness,
    changeModeViewBusiness,
    changeStatusGetFromDatabaseCompletedBusiness,
    getAllDraggablesBusiness,
    toggleCheckedAllBusiness
} from "../../store/actions/business";

import Header from "../../components/CRM/Header";
import Draggable from "../../components/general/Draggable";
import Button from "../../components/general/Button";
import ButtonIcon from "../../components/general/ButtonIcon";
import TableEditBusiness from "../../components/general/TableEditBusiness";
import Message from "../../components/general/Message";
import Select from "../../components/general/Select";
import SelectMulti from "../../components/general/SelectMulti";
import InputGeneral from "../../components/general/InputGeneral";
import MessageConfirmation from "../../components/general/MessageConfirmation";

import { changeStatusAllSpecialistsUpdatedServiceQueue, setLastPositionSpecialistServiceQueue } from "../../store/actions/queue";

import UserController from "../../controllers/UserController";
import BusinessController from "../../controllers/BusinessController";
import MidiaOriginController from "../../controllers/MidiaOriginController";
import ClientController from "../../controllers/ClientController";
import ActivityController from "../../controllers/ActivityController";
import ProspectionTeamHierarchyController from "../../controllers/ProspectionTeamHierarchyController";
import WhatsAppController from "../../controllers/WhatsAppController";

const initialState = {
    allDraggablesComponents: [], //for jsx draggables
    allDraggables: [],
    allDraggablesNotFiltered: [],
    filterByStatusActive: false,
    allUsers: [],
    allBusinessInitialLoaded: false,
    allBusinessUpdated: false,

    headerSummary: [
        {
            column: 0,
            count: 0,
            money: "0,00"
        },
        {
            column: 1,
            count: 0,
            money: "0,00"
        },
        {
            column: 2,
            count: 0,
            money: "0,00"
        },
        {
            column: 3,
            count: 0,
            money: "0,00"
        },
        {
            column: 4,
            count: 0,
            money: "0,00"
        }
    ],
    
    // start modal general
    showModalMoreActions: false,
    actionModalMoreActions: business,
    // ends modal general
    
    // start area draggable
    x: 0,
    y: 0,
    containerDraggable: null,
    targetCurrentDraggable: null,
    amountColumns: 5,
    activeColumn: "", //active or ""
    activeFooter: "", //active or ""
    breakPointsColumns: [],
    widthColumn: null,
    actionTypeFooterDraggable: "", //delete, lost, gain, or move
    newDraggableForColumn: "", //0, 1, 2, 3, or 4 (progress column)
    //ends area draggable

    // start navigation business
    modeBusiness: modeBusinessDraggable, //draggable, list or details
    // ends navigation business

    allDraggablesChecked: false, //false as default
    hasOneDraggableChecked: false, //false as default
    targetDraggableToDetail: {},
    buttonDraggableReleased: true,

    // start message modal
    message_type: "information",
    message_show: false,
    message: "",
    // ends message modal

    // start options more filters
    filtered: false,
    dateCreateStartMoreFilters: null,
    dateCreateEndsMoreFilters: null,
    dateClosedStartMoreFilters: null,
    dateClosedEndsMoreFilters: null,
    showModalMoreFilters: false,
    optionsClientMoreFilters: [],
    optionsSelectFrom : [],
    optionsStepFunnelMoreFilters: [
        {
            name: "Lead",
            value: 1,
            checked: false
        },
        {
            name: "Primeiro Contato",
            value: 2,
            checked: false
        },
        {
            name: "Atendimento Presencial",
            value: 3,
            checked: false
        },
        {
            name: "Fechamento",
            value: 4,
            checked: false
        },
        {
            name: "Reagendar",
            value: 5,
            checked: false
        },
    ],
    optionsCampaignMoreFilters: [
        {
            name: "Facebook Co.",
            value: "Facebook Co.",
            checked: false,
        },
        {
            name: "Google",
            value: "Google",
            checked: false,
        }
    ],

    optionsAttendants: [],
    // ends options more filters

    allOwnersNotFiltered: [],
    optionsOwnersLead: [],
    optionsUnitCompany: [],
    optionsSelectStatus: [
        {
            name: "Em Andamento",
            value: "OPEN",
            checked: false
        },
        {
            name: "Ganhos",
            value: "GAIN",
            checked: false
        },
        {
            name: "Perdidos",
            value: "LOSS",
            checked: false
        },
        {
            name: "Pausados",
            value: "PAUSED",
            checked: false
        },
    ],
    optionsChosenStatusBusiness: {
        name: "Em Andamento",
        value: "OPEN"
    },

    optionsProspectionOwnersTransfer: [],
    optionChosenProspectionOwner: null,

    // start about service queue
    idCurrentCompanyUnit: 2,
    allSpecialistsNotFiltered: [],
    optionOwnerSpecialistOrManagers: [], //WARNING: just one specialist in the array
    objManagerByCompanyUnit: {},
    // ends about service queue

    modeFilterDateBusiness: 1, // 1 - week, 2 - All

    // start message confirmantion modal
    message_confirmation: "",
    message_show_confirmation: false,
    message_type_confirmation: "",
    statusConfirmation: false,
    // ends message confirmantion modal

    showFieldAttendantSelect: false,
    showFieldSendigMessage: false,

    fieldMessageWhatsApp: "",
}

const activityController = new ActivityController();
const businessController = new BusinessController();
const clientController = new ClientController();
const userController = new UserController();
const midiaOriginController = new MidiaOriginController();
const prospectionTeamHierarchyController = new ProspectionTeamHierarchyController();
const whatsAppController = new WhatsAppController();

var durationStopwatch = 0;
class Business extends Component
{        
    state = { ...initialState }

    constructor(props)
    {
        super(props);
        this.containerDraggable = React.createRef();
    }

    componentDidMount = async () => 
    {
        document.title = `${APPLICATION_NAME} - Negócios`;

        this.setInitialAndFinalDateFromWeek();

        this.calculateBreackPointsColumns();
        await this.setCurrentCompanyUnit();
        await this.getCompanyUnit();
        await this.getOwners();
        await this.getFirstOwnerAtQueueByCompanyUnit();
        await this.getManagerByCompanyUnit();
        await this.applyFilters();
        await this.getAllUsers();
        await this.getAttendantsForFilters();
        await this.getProspectionOwnersToTransfer();
        await this.getOptionsSelectFrom();
    }

    componentDidUpdate = async (prevProps, prevState) => 
    {
        // console.log("BUSINESS prevProps ", prevProps);
        // console.log("BUSINESS this.props ", this.props);

        if (prevProps.hasPermissionsLoaded !== this.props.hasPermissionsLoaded) 
		{
			if (!this.props.permissions.toView) { this.props.history.push(`/activities`); }
		}

        // for get allDraggables global state (only initially)
        if (this.state.allBusinessUpdated)
        {          
            await this.setState({allBusinessUpdated: false});
            await this.applyFilters();
        }  

        // for new deal business
        if (this.props.newDeal !== undefined)
        {
            if (prevProps!== undefined && (prevProps.newDeal?.id !== this.props.newDeal.id))
            {
                let {id, column, money, name, title, stamp} = this.props.newDeal;
                let newDraggable = {
                    id,
                    x: 0,
                    y: 0,
                    title,
                    name,
                    money,
                    column,
                    stamp
                }
                let allDraggables = this.state.allDraggables;
                allDraggables.push(newDraggable);
                this.setState({allDraggables});

                this.props.showModalMoreActions({show: false, type: null});
            }
        }
       
        // for change view mode business
        if (this.props.modeBusinesFinal !== undefined)
        {
            if (this.props.modeBusinesFinal !== this.state.modeBusiness)
            {
                this.setState({modeBusiness: this.props.modeBusinesFinal});
            }
        }

        // for getting all users (client)
        if ((this.state.allUsers.length !== this.props.allUsers.length))
        {
            await this.getAllUsers();
        }   

        // getting allSpecialists of the service queue, for each change in the queue.
        if (this.props.queue.allSpecialistsNotFilteredHasUpdated && this.props.queue.allSpecialistsNotFiltered.length > 0)
        {
            await this.props.changeStatusAllSpecialistsUpdatedServiceQueue(false);
            await this.getFirstOwnerAtQueueByCompanyUnit();
            await this.getManagerByCompanyUnit();
        }
    }

    calculateHeaderSummary = () =>
    {
        const allBusiness = this.state.allDraggables;
        const headerSummary = this.state.headerSummary;
        let newSummary = [];

        for (const summary of headerSummary)
        {
            newSummary.push({
                ...summary,
                count: allBusiness.filter(b => b.column === summary.column).length,
                money: allBusiness.reduce((total, b) => {
                    total = parseFloat(total);
                    if (b.column === summary.column)
                    {
                        total += parseFloat(b.money)
                    }
                    return total.toFixed(2);
                }, 0)
            });
        }

        this.setState({ headerSummary: newSummary });
    }

    calculateBreackPointsColumns = async () =>
    {
        let containerWidth = this.containerDraggable.current.clientWidth;
        let widthColumn = await containerWidth/this.state.amountColumns;
        let points = [];

        for (let i = 0; i < this.state.amountColumns; i++) 
        {
            let obj = {
                i: i,
                point: widthColumn*i
            }    
            points.push(obj);
        }

        await this.setState({widthColumn, breakPointsColumns: points});
    }

    getFinalColumn = (deltaX, currentColumn) =>
    {
        let widthColumn = this.state.widthColumn;
        let column = 0;        
        
        if (deltaX >= 0)
        {
            let plusColumns = parseInt(deltaX/widthColumn);
            column = currentColumn + plusColumns;
            // INFORMATION: don't remove the follow lines
            // console.log(`deltaX: ${deltaX}, currentColumn: ${currentColumn}, plusColumns: ${plusColumns}, widthColumn: ${widthColumn}`);
        }
        else
        {
            deltaX = deltaX*(-1);
            deltaX = deltaX + (widthColumn/2);
            let lessColumns = parseInt(deltaX/widthColumn);
            column = currentColumn - lessColumns;
            // INFORMATION: don't remove the follow lines
            // console.log(`deltaX: ${deltaX}, currentColumn: ${currentColumn}, lessColumns: ${lessColumns}, widthColumn: ${widthColumn}`);
        }

        if (column > 4)
        {
            column = 4;
        }
        else if (column < 0)
        {
            column = 0;
        }

        return column;
    }

    onStart = (data, draggable) => 
    {
        // INFORMATION: don't remove the follow lines
        // console.log("onStart");
        this.setState({targetCurrentDraggable: draggable});
        this.startStopwatch();
    }
    
    onDrag = async (data, draggable) =>
    {
        // INFORMATION: don't remove the follow lines
        // console.log("onDrag");
        let {x, y} = data;
        let activeColumn = this.getFinalColumn(x, draggable.column);
        this.setState({x, y, activeColumn, activeFooter: "active"});
    }
    
    onStop = async (data, draggable) =>
    {
        // console.log("data: ", data);

        let {x} = data;
        let deltaX = x;
        let targetDraggable = this.state.targetCurrentDraggable;
        let curretColumn = targetDraggable.column;
        let finalColumnDraggable = this.getFinalColumn(deltaX, curretColumn);

        let allBusiness = [...this.state.allDraggables];
        
        const index = allBusiness.findIndex(b => b.id === draggable.id);

        allBusiness[index] = {
            ...draggable,
            column: finalColumnDraggable
        };

        await this.setState({ allDraggables: allBusiness });
        this.calculateHeaderSummary();

        if (draggable.column !== finalColumnDraggable)
        {
            await businessController.update(draggable.id, { BUS_STEP: finalColumnDraggable + 1 });
        
            const annotations = `● Movido para etapa ${this.state.optionsStepFunnelMoreFilters[finalColumnDraggable].name}.`;

            await activityController.create({
                ACT_TITLE: "INFORMAÇÃO",
                ACT_TYPE: HISTORIC_TYPE_ACTIVITY,
                ACT_ANNOTATIONS: annotations.toUpperCase(),
                ACT_ID_BUSINESS: draggable.id,
                ACT_ID_PERSON_CLIENT: draggable.clientId,
                ACT_ID_CREATE_PERSON: this.props.user.PER_ID,
                ACT_FINISHED: 1,
                hasFile: false,
                file: null
            });
        }

        this.setState({activeColumn: "", activeFooter: ""});
        this.getActionsButtonFooterDraggable();
        await this.stopStopwatch();
    }

    startStopwatch () {
        durationStopwatch = 0;

        this.stopwatch = setInterval(function () {
            durationStopwatch++;
        }, 1); 
    }
    
    stopStopwatch = async () => 
    {
        clearInterval(this.stopwatch);

        if (durationStopwatch <= 130)
        {
            let targetCurrentDraggable = await this.state.targetCurrentDraggable;
            this.setState({activeFooter: "", activeColumn: ""});
            window.open(`/business/details/${targetCurrentDraggable.id}`);
        }  

        durationStopwatch = 0;
    }

    onSendingMessageWhatsAppOutside = async () =>
    {
        let statusConfirmation = await this.state.statusConfirmation;
        let objectConfirmation = await this.state.objectConfirmation;
        let fieldMessageWhatsApp = await this.state.fieldMessageWhatsApp;

        if (!statusConfirmation)
        {
            await this.messageConfirmation("information", `Enviar uma mensagem para ${objectConfirmation.clientPhoneNumber}? Se sim, coloque uma mensagem no campo abaixo:`);
        }
        else
        {
            
            if (fieldMessageWhatsApp.toString().trim() === "")
            {
                await this.messageConfirmation("warning", `Preencha o campo corretamente:`);
            }
            else
            {
                await this.sendMessageOutside();
                await this.setState({fieldMessageWhatsApp: ""});
                await this.onClickNoConfirmation();
            }
        }
    }

    getActionsButtonFooterDraggable = () =>
    {
        let actionType = this.state.actionTypeFooterDraggable;
        
        if (actionType !== "")
        {
            let targetDraggable = this.state.targetCurrentDraggable;

            switch (actionType)
            {
                case "delete":
                    actionType = "Excluir";
                    break;

                case "lost":
                    actionType = "Perdido";
                    break;

                case "gain":
                    actionType = "Ganho";
                    break;

                case "move":
                    actionType = "Mover ";
                    break;

                default:
                    break;
            }

            this.message("information", `${actionType} ${targetDraggable.title}`);
        }
    }

    getCompanyUnit = async () => 
    {
        const result = await userController.getOwnersForUserOnAccess(this.props.user.PER_ID);

        if (result.status)
        {
            const allUnits = result.data.data.filter(u => u.UNT_STATUS === 1);
            
            let units = [];
            let allOwnersNotFiltered = [];
            
            for (const unit of allUnits)
            {
                units.push(
                    {
                        name: unit.UNT_NAME,
                        value: unit.UNT_ID,
                        checked: allUnits.length === 1 ? true : false
                    }
                );

                for (const owner of unit.owners)
                {
                    if (owner.PER_STATUS === 1)
                    {
                        allOwnersNotFiltered.push(
                            {
                                ...owner,
                                PER_ID_COMPANY_UNIT: unit.UNT_ID
                            }
                        );
                    }
                }
            }

            this.setState({ optionsUnitCompany: units, allOwnersNotFiltered });
        }
    }

    setCurrentCompanyUnit = async () =>
    {
        let companies = this.props.user.unit_companies;
        await this.setState({idCurrentCompanyUnit: companies[0].UNT_ID});
    }

    getOwners = async () => 
    {
        const allUnitsChecked = await this.state.optionsUnitCompany.filter(u => u.checked).map(u => u.value);
        const allOwnersNotFiltered = await this.state.allOwnersNotFiltered;
        const allOwnersInState = await this.state.optionsOwnersLead;

        let owners = [];

        for (const owner of allOwnersNotFiltered)
        {
            if (allUnitsChecked.length === 0 || allUnitsChecked.includes(owner.PER_ID_COMPANY_UNIT))
            {
                owners.push(
                    {
                        id: owner.PER_ID,
                        name: getFullNameFisrtCharsUppercase(owner.PER_NAME),
                        value: owner.PER_ID,
                        checked: allOwnersInState.find(o => o.value === owner.PER_ID)?.checked || false
                    }
                );
            }
        }

        owners = owners.sort(function (a, b) 
            {
                if (a.name > b.name) 
                {
                  return 1;
                }
                if (a.name < b.name) 
                {
                  return -1;
                }
                return 0;
            });

        await this.setState({ optionsOwnersLead: owners });
    }

    getAttendantsForFilters = async() =>
    {
        const 
            [
                result, 
                user
            ] 
            = await Promise.all(
            [
                await prospectionTeamHierarchyController.get(),
                userController.getById(this.props.user.PER_ID)
            ]
        );

        if (!result.status) { return; }

        const {coordinators} = result.data.data;
        const userId = this.props.user.PER_ID;
        const userUnits = user.data.unit_companies.map(u => u.UNT_ID);
        
        let optionsAttendants = prospectionTeamHierarchyController.getProspectionOwners(coordinators, userId, this.props.permissions, userUnits);

        let activeAttendants = optionsAttendants.filter(a => a.status === 1 && a.value !== 0);
        let inactiveAttendants = optionsAttendants.filter(a => a.status === 2);

        activeAttendants = sortInAlphabeticalOrder(activeAttendants);
        inactiveAttendants = sortInAlphabeticalOrder(inactiveAttendants);

        activeAttendants.unshift(
            {
                name: "[SEM ATENDENTE]",
                value: 0,
                checked: false,
                status: 1,
            }
        );

        inactiveAttendants = inactiveAttendants.map(a => {
            a.name = `INATIVO(A) - ${a.name}`;
            return a;
        });

        optionsAttendants = [...activeAttendants, ...inactiveAttendants];

        await this.setState({ optionsAttendants });
    }

    getProspectionOwnersToTransfer = async() =>
    {
        const result = await prospectionTeamHierarchyController.get();

        if (!result.status) { return; }

        const attendants = [];
        const userId = this.props.user.PER_ID;

        const {coordinators} = result.data.data;
        const coordinator = coordinators.find(c => c.id === userId);
            
        if (coordinator) 
        {
            for (const supervisor of coordinator.supervisors) 
            {
                for (const attendant of supervisor.attendants)
                {
                    attendants.push({
                        name: attendant.name,
                        value: attendant.id,
                        checked: false
                    });
                }
            }
        } 
        else 
        {
            let supervisor = null;
            for (const coordinator of coordinators) 
            {
                if (!supervisor)
                {
                    supervisor = coordinator.supervisors.find(s => s.id === userId);
                }
            }

            if (supervisor) 
            {
                for (const attendant of supervisor.attendants)
                {
                    attendants.push(
                        {
                            name: attendant.name,
                            value: attendant.id,
                            checked: false
                        }
                    );
                }
            }
            else
            {
                let attendant = null;
                for (const coordinator of coordinators)
                {
                    for (const supervisor of coordinator.supervisors)
                    {
                        if (!attendant)
                        {
                            attendant = supervisor.attendants.find(a => a.id === userId);
                            if (attendant)
                            {
                                attendant = {
                                    ...attendant,
                                    supervisorId: supervisor.id
                                }
                            }
                        }
                    }
                }
                if (attendant)
                {
                    let supervisor = null;
                    for (const coordinator of coordinators) 
                    {
                        if (!supervisor)
                        {
                            supervisor = coordinator.supervisors.find(s => s.id === attendant.supervisorId);
                        }
                    }
                    if (supervisor) 
                    {
                        for (const attendant of supervisor.attendants)
                        {
                            attendants.push({
                                name: attendant.name,
                                value: attendant.id,
                                checked: false
                            });
                        }
                    } 
                }
                else
                {
                    if (this.props.permissions.toViewAll)
                    {
                        for (const coordinator of coordinators) 
                        {
                            for (const supervisor of coordinator.supervisors)
                            {
                                for (const attendant of supervisor.attendants)
                                {
                                    attendants.push({
                                        name: attendant.name,
                                        value: attendant.id,
                                        checked: false
                                    });
                                }
                            }
                        }
                    }
                }
            }
        }

        attendants.sort((a, b) => {
            if (a.name < b.name) {
                return -1;
            } else if (a.name > b.name) {
                return 1;
            } else {
                return 0;
            }
        });

        await this.setState({
            optionsProspectionOwnersTransfer: attendants
        });
    }

    getAllUsers = async () =>
    {
        const filter = this.state.filtered === true ? 2 : 1;
        await this.props.getAllUsersLeads(filter);
        let allUsers = await this.props.allUsers;
        let optionsClientMoreFilters = [];

        for (let user of allUsers)
        {
            let client = {
                name: user.name,
                value: user.id,
                checked: false,
            };

            optionsClientMoreFilters.push(client);
        }

        await this.setState({allUsers, optionsClientMoreFilters});
    }

    getOptionsSelectFrom = async () => 
    {
        const result = await midiaOriginController.get();
        let options = [];

        if (result.status)
        {
            for (const midiaOrigin of result.data.data)
            {
                options.push(
                    {
                        name: midiaOrigin.MID_NAME,
                        value: midiaOrigin.MID_ID,
                        checked: false
                    }
                );
            }
        }

        this.setState({ optionsSelectFrom: options });
    }

    loadAllDraggablesByColumn = (column) =>
    {
        let allDraggablesComponents = this.state.allDraggables;

        return allDraggablesComponents
        ?.filter(e => {
            return e.column === column;
        })
        .map((e, i) => {

            return (
                <div className="containerExtraDraggableBusiness" key={`${i}`}>
                    <Draggable
                        key={`${i}`}
                        onDrag={(data) => this.onDrag(data, e)}
                        onStart={(data) => this.onStart(data, e)}
                        onStop={(data) => this.onStop(data, e)}
                        position={{x: e.x, y: e.y}}
                        width={this.props.widthColumn}                    
                    >
                        <div 
                            className="containerDraggableBusiness"
                        >
                            {
                                (e.stamp && e.stamp.style !== "none") &&
                                <div className="containerStampDraggableBusiness">
                                    <span className="stampDraggableBusiness" style={{backgroundColor: `var(--color-${e.stamp.style})`}}/>
                                </div>
                            }
                            <span className="titleDraggableBusiness">{e.title}</span>
                            <span className="nameDraggableBusiness">{e.name}</span>
                            <div className="row no-gutters lineDraggablesBusiness">
                                <span className="moneyDraggableBusiness"><i className="fas fa-user-alt"></i>&nbsp;R$&nbsp;{formatedMoneyBRAFromFloat(e.money)}</span>
                            </div>
                        </div>
                    </Draggable>
                    {
                        this.state.activeFooter !== "active" && this.props.whatsapp.toView &&
                        <ButtonIcon
                            classaditional="buttonWhatsappDraggableBusiness"
                            icon="fab fa-whatsapp"
                            onClick={async () => {
                                let companyUnit = e.companyUnit;
                                let avaliable = await this.checkingAvaliableConnectionWhatsApp(companyUnit);
                                if (!avaliable) { return; }

                                await this.setState({
                                    objectConfirmation: e,
                                    nameFunctionYesModalConfirmation: "onSendingMessageWhatsAppOutside",
                                    showFieldSendigMessage: true
                                });
                                await this.onSendingMessageWhatsAppOutside();
                            }}
                        />
                    }
                </div>
            )
        });
    }


    checkingAvaliableConnectionWhatsApp = async (idCompanyUnit) =>
    {
        let idsCompanyUnitsConnectionsAvaliables = [6, 13, 11, 10, 7, 5, 8];
        let avaliable = (idsCompanyUnitsConnectionsAvaliables.includes(idCompanyUnit));

        if (!avaliable)
        {
            await this.message("warning", "Opsssss, essa unidade ainda não tem conexão com o whatsapp.");
        }

        return avaliable;
    }

    hasAllDraggablesChecked = async () =>
    {
        let allDraggables = await this.state.allDraggables;
        let allDraggablesChecked = true;
        let hasOneDraggableChecked = false;

        if (allDraggables.length > 0)
        {
            allDraggables.forEach(d => {
                if (!d.checked) allDraggablesChecked = false;
                if (d.checked) hasOneDraggableChecked = true;
            });
        }
        else
        {
            allDraggablesChecked = false;
        }
        
        await this.setState({allDraggablesChecked, hasOneDraggableChecked});
    }

    newDeal = (column) => 
    {
        let data = {
            show: true,
            type: business
        }
        this.props.showModalMoreActions(data);
    }

    message = (type, message) =>
    {
        this.setState({
            message_type: type,
            message: message,
            message_show: true
        });
    }

    zeroPad = (num, places) => String(num).padStart(places, '0')

    getAllBusiness = async (filters) => 
    {
        let payload = [];

        const result = await businessController.getByFilters(filters);
        
        if (result.status) 
        {
            for (const business of result.data.data) 
            {
                let owner = business.BUS_ID_PERSON_OWNER === null ? null : {
                    id: business.BUS_ID_PERSON_OWNER,
                    name: business.OWNER_NAME,
                    value: business.BUS_ID_PERSON_OWNER
                };

                let statusDate = "";
                if (business.BUS_STATUS_DATE && business.BUS_STATUS === "GAIN")
                {
                    const date = new Date(business.BUS_STATUS_DATE);
                    statusDate = `${this.zeroPad(date.getDate(), 2)}/${this.zeroPad((date.getMonth() + 1), 2)}/${date.getFullYear()}`;
                }

                let ownerPermissions = {...this.props.leadOwnersPermissions};
                if ((owner === null && ownerPermissions.toInsert === true) || (owner !== null && ownerPermissions.toUpdate === true)) 
                {
                    ownerPermissions.toUpdate = true;
                } else
                {
                    ownerPermissions.toUpdate = false;
                }

                let prospectionOwner = null;
                if (business.BUS_ID_PERSON_PROSPECTION_OWNER)
                {
                    prospectionOwner = {
                        id: business.BUS_ID_PERSON_PROSPECTION_OWNER,
                        value: business.BUS_ID_PERSON_PROSPECTION_OWNER,
                        name: business.PROSPECTION_OWNER_NAME
                    };
                }

                payload.push(
                    {
                        id: business.BUS_ID,
                        x: 0,
                        y: 0,
                        title: business.BUS_TITLE, 
                        clientId: business.PER_ID,
                        clientPhoneNumber: business.PER_PHONE_ONE,
                        name: business.PER_NAME,
                        campaign: business.PER_CAMPAIGN_NAME,
                        midiaOrigin: business.PER_ID_MIDIA_ORIGIN,
                        money: business.BUS_MONEY_VALUE,
                        column: business.BUS_STEP - 1,
                        checked: false,
                        createdAt: new Date(`${business.BUS_DATE_CREATED} 00:00`),
                        status: (business.BUS_STATUS == null) ? "" : business.BUS_STATUS,
                        statusDate,
                        companyUnit: business.PER_ID_COMPANY_UNIT,
                        owner,
                        ownerPermissions,
                        idServiceERP: business.BUS_ID_SERVICE_ERP,
                        stamp: {
                            id: business.STA_ID,
                            name: business.STA_NAME,
                            style: business.STA_STYLE
                        },
                        prospectionOwner
                    }
                );
            };
        }

        await this.props.getAllDraggablesBusiness(payload);
    }

    getManagerByCompanyUnit = async () =>
    {
        let optionOwnerSpecialistOrManagers = await this.state.optionOwnerSpecialistOrManagers || [];
        let idCurrentCompanyUnit = await this.state.idCurrentCompanyUnit;
        let objManagerByCompanyUnit = {};

        let responseManager = await userController.getManagerOfCompanyUnit(idCurrentCompanyUnit);

        if (responseManager.status && responseManager.data !== null && responseManager.data !== undefined)
        {
            let manager = responseManager.data;
            manager = {
                id: manager.PER_ID,
                name: manager.PER_NAME,
                value: manager.PER_ID,
                checked: false
            };
            optionOwnerSpecialistOrManagers.push(manager);
            objManagerByCompanyUnit = manager;
    
            await this.setState({optionOwnerSpecialistOrManagers, objManagerByCompanyUnit});
        }
    }

    getFirstOwnerAtQueueByCompanyUnit = async () =>
    {
        let idCompanyUnit = await this.state.idCurrentCompanyUnit;
        let optionOwnerSpecialistOrManagers = [];
        let allSpecialistsNotFiltered = await this.props.queue.allSpecialistsNotFiltered;
        
        // getting just a service queue (of a company unit)
        if (this.props.user.unit_companies.length === 1 && allSpecialistsNotFiltered.length > 0)
        {
            let allSpecialists = [];
    
            allSpecialistsNotFiltered.forEach(u => {
                if (u.idCompanyUnit === idCompanyUnit)
                {
                    u.specialists.forEach(s => {
    
                        if (s.frozen === 2) //just the not-frozen specialists.
                        {
                            allSpecialists.push(
                                {
                                    id: s.id,
                                    name: getFullNameFisrtCharsUppercase(s.name),
                                    value: s.id,
                                    checked: false
                                }
                            );
                        }
                    });
                }
            });

            optionOwnerSpecialistOrManagers.push(allSpecialists[0]);

            await this.setState({
                allSpecialistsNotFiltered,
                optionOwnerSpecialistOrManagers
            });          
        }
    }

    applyFilters = async () =>
    {   
        let filters = {
            BUS_STATUS: this.state.optionsChosenStatusBusiness.value,
            USER_ID: this.props.user.PER_ID
        };

        // GET ALL CHECKED OPTIONS FILTERS
        const optionsOwners = this.state.optionsOwnersLead.filter(o => o.checked).map(o => o.value);
        const optionsProspectionOwners = this.state.optionsAttendants.filter(o => o.checked).map(o => o.value);
        const optionsCompanyUnits = this.state.optionsUnitCompany.filter(o => o.checked).map(o => o.value);
        const optionsSteps = this.state.optionsStepFunnelMoreFilters.filter(o => o.checked).map(o => o.value);
        const optionsLeads = this.state.optionsClientMoreFilters.filter(o => o.checked).map(o => o.value);
        const optionsMidiaOrigin = this.state.optionsSelectFrom.filter(o => o.checked).map(o => o.value);
        const optionsCampaigns = this.state.optionsCampaignMoreFilters.filter(o => o.checked).map(o => o.value);
        const dateCreatedStart = this.state.dateCreateStartMoreFilters;
        const dateCreatedEnds = this.state.dateCreateEndsMoreFilters;
        const dateClosedStart = this.state.dateClosedStartMoreFilters;
        const dateClosedEnds = this.state.dateClosedEndsMoreFilters;

        // VERIFY FILTERS
        optionsOwners.length > 0 && (filters.OWNERS = optionsOwners);
        optionsProspectionOwners.length > 0 && (filters.PROSPECTION_OWNERS = optionsProspectionOwners);
        optionsCompanyUnits.length > 0 && (filters.PER_ID_COMPANY_UNIT = optionsCompanyUnits);
        optionsSteps.length > 0 && (filters.BUS_STEP = optionsSteps);
        optionsLeads.length > 0 && (filters.BUS_ID_PERSON_CLIENT = optionsLeads);
        optionsMidiaOrigin.length > 0 && (filters.PER_ID_MIDIA_ORIGIN = optionsMidiaOrigin);
        optionsCampaigns.length > 0 && (filters.PER_CAMPAIGN_NAME = optionsCampaigns);
        dateCreatedStart !== null && (filters.BUS_DATE_CREATED_START = dateCreatedStart);
        dateCreatedEnds !== null && (filters.BUS_DATE_CREATED_ENDS = dateCreatedEnds);
        dateClosedStart !== null && (filters.BUS_STATUS_DATE_START = dateClosedStart);
        dateClosedEnds !== null && (filters.BUS_STATUS_DATE_ENDS = dateClosedEnds);

        await this.getAllBusiness(filters);

        await this.setState({ allDraggables: this.props.allDraggables });
        await this.hasAllDraggablesChecked();
        await this.calculateHeaderSummary();
    }

    setDefaultMoreFilters = async() =>
    {   
        const optionsSteps = this.state.optionsStepFunnelMoreFilters.filter(o => o.checked).map(o => o.value);
        const optionsLeads = this.state.optionsClientMoreFilters.filter(o => o.checked).map(o => o.value);
        const optionsMidiaOrigin = this.state.optionsSelectFrom.filter(o => o.checked).map(o => o.value);
        const optionsCampaigns = this.state.optionsCampaignMoreFilters.filter(o => o.checked).map(o => o.value);

        if (optionsSteps.length > 0)
        {
            await this.setState({ optionsStepFunnelMoreFilters: this.state.optionsStepFunnelMoreFilters.map(f => ({ ...f, checked: false })) });
        }

        if (optionsMidiaOrigin.length > 0)
        {
            await this.setState({ optionsSelectFrom: this.state.optionsSelectFrom.map(m => ({ ...m, checked: false })) });
        }

        if (optionsCampaigns.length > 0)
        {
            await this.setState({ optionsCampaignMoreFilters: this.state.optionsCampaignMoreFilters.map(c => ({ ...c, checked: false })) });
        }

        if (optionsLeads.length > 0)
        {
            await this.setState({ optionsClientMoreFilters: this.state.optionsClientMoreFilters.map(l => ({ ...l, checked: false })) });
        }

        await this.setState({
            dateCreateStartMoreFilters: null,
            dateCreateEndsMoreFilters: null,
            dateClosedStartMoreFilters: null,
            dateClosedEndsMoreFilters: null,
        });

    }

    saveValue = async (id, field, value) =>
    {
        if (value === "")
        {
            await this.props.changeStatusGetFromDatabaseCompletedBusiness(false);
            await this.applyFilters();
            return this.message("error", "Este campo não pode ficar em branco!");
        }

        let result = {
            status: true,
        }

        switch (field)
        {
            case "PER_NAME":
                result = await clientController.update(id, {
                    [field]: value
                });
                break;

            case "BUS_TITLE":
            case "BUS_ID_PERSON_OWNER":
            case "BUS_MONEY_VALUE":

                if (field === "BUS_MONEY_VALUE")
                {
                    value = value.replace(".", "").replace(",", ".");
                }

                result = await businessController.update(id, {
                    [field]: value
                });

                if (field === "BUS_ID_PERSON_OWNER")
                {
                    let objManagerByCompanyUnit = await this.state.objManagerByCompanyUnit;
                    if (result.status && objManagerByCompanyUnit.id !== value)
                    {
                        this.props.setLastPositionSpecialistServiceQueue(value);
                    }
                    
                    const annotations = `● Adicionou ${this.state.optionOwnerSpecialistOrManagers.find(s => s.id === value).name} como proprietário.`;
            
                    await activityController.create({
                        ACT_TITLE: "INFORMAÇÃO",
                        ACT_TYPE: HISTORIC_TYPE_ACTIVITY,
                        ACT_ANNOTATIONS: annotations.toUpperCase(),
                        ACT_ID_BUSINESS: id,
                        ACT_ID_PERSON_CLIENT: this.state.allDraggables.find(b => b.id === id).clientId,
                        ACT_ID_CREATE_PERSON: this.props.user.PER_ID,
                        ACT_FINISHED: 1,
                        hasFile: false,
                        file: null
                    });
                }

                break;

            case "BUS_ID_PERSON_PROSPECTION_OWNER":

                if (this.state.optionsChosenStatusBusiness.value !== "OPEN")
                {
                    await this.props.changeStatusGetFromDatabaseCompletedBusiness(false);
                    await this.applyFilters();
                    return this.message("error", "Só é possível transferir negócios em andamento!");
                }

                await this.applyFilters();
                const businessData = this.state.allDraggables.find(b => b.id === id);
                const newAttendant = this.state.optionsProspectionOwnersTransfer.find(a => a.value === value);
                await this.transferProspectionOwnerBusiness(businessData, newAttendant);
                break;

            default:
                break;
        }

        if (!result.status) 
        {
            this.message("error", result.message);
        }

        await this.props.changeStatusGetFromDatabaseCompletedBusiness(false);
        await this.applyFilters();
    }

    changeValue = async (newValue, id, propertyName = null) =>
    {
        if (propertyName !== null)
        {
            // INFORMATION: don't remove the follow lines
            // console.log(`newValue: ${newValue}, id: ${id}, propertyName: ${propertyName}`);
            this.props.changeDealBusiness({[propertyName]: newValue, id, propertyName});
        }
    }

    setInitialAndFinalDateFromWeek = async () => 
    {
        let initial = new Date();
        initial = initial.setDate(initial.getDate() - 7);
        initial = formatedDateTimeToUSA(initial);
        let final = new Date();
        final = formatedDateTimeToUSA(final);

        await this.setState({
            dateCreateStartMoreFilters: initial, 
            dateCreateEndsMoreFilters: final
        });
    }

    transferBusinessChecked = async () =>
    {
        const checkedBusiness = this.state.allDraggables.filter(b => b.checked);

        for (const business of checkedBusiness) 
        {
            await this.transferProspectionOwnerBusiness(business, this.state.optionChosenProspectionOwner);
        }

        await this.setState({message_show_confirmation: false});

        this.message("success", "Négocio(s) transferido(s) com sucesso!");
        this.applyFilters();
    }

    transferProspectionOwnerBusiness = async (business, newAttendant) =>
    {
        await businessController.update(business.id, {
            BUS_ID_PERSON_PROSPECTION_OWNER: newAttendant.value
        });

        let annotations = `Adicionou ${newAttendant.name} como atendente.`;
        if (business.prospectionOwner)
        {
            annotations = `Atendimento transferido de ${business.prospectionOwner.name} para ${newAttendant.name}`;
        }

        await activityController.create({
            ACT_TITLE: "TRANSFERÊNCIA",
            ACT_TYPE: TRANSFER_TYPE_ACTIVITY,
            ACT_ANNOTATIONS: annotations.toUpperCase(),
            ACT_ID_BUSINESS: business.id,
            ACT_ID_PERSON_CLIENT: business.clientId,
            ACT_ID_CREATE_PERSON: this.props.user.PER_ID,
            ACT_FINISHED: 1,
            hasFile: false,
            file: null
        });
    }

    sendMessageOutside = async () =>
    {
        let fieldMessageWhatsApp = await this.state.fieldMessageWhatsApp;
        let objectConfirmation = await this.state.objectConfirmation;
        let user = this.props.user;

        let fields = {
            message: fieldMessageWhatsApp,
            phoneClient: objectConfirmation.clientPhoneNumber,
            nameClient: objectConfirmation.name,
            idCompanyUnit: objectConfirmation.companyUnit,
            idUserCRM: user.PER_ID,
            nameUserCRM: user.PER_NAME
        };

        let response = await whatsAppController.sendMessageOutside(fields);

        if (response.status)
        {
            await this.message("success", "Obaaaaa, mensagem enviada com sucesso!");
        }
        else
        {
            await this.message("error", "Opsssss, tivemos algum problema. Contacte o TI em caso de persistência!");
        }
    }

    onTransferBusiness = async () =>
    {
        let statusConfirmation = await this.state.statusConfirmation;

        if (!statusConfirmation)
        {
            await this.messageConfirmation("information", `Selecione um atendente:`);
        }
        else
        {
            await this.transferBusinessChecked();
            await this.onClickNoConfirmation();
        }
    }

    seeMessagesWhatsApp = async () =>
    {
        let objectGeneralConfirmation = await this.state.objectGeneralConfirmation;
        
        let {clientPhoneNumber, clientName, companyUnit} = objectGeneralConfirmation;
        clientName = removeAccents(clientName.toString().split(" ")[0]);
        let url = `/whatsapp/${clientPhoneNumber}/${clientName}/${companyUnit}`;
        window.open(url);
    }

    messageConfirmation = async (type, message) =>
    {
        await this.setState({
            message_type_confirmation: type,
            message_confirmation: message,
            message_show_confirmation: true
        });
    }

    onClickYesConfirmation = async () =>
    {
        let fn = this.state.nameFunctionYesModalConfirmation;

        await this.setState({
            statusConfirmation: true,
            message_show_confirmation: false,
            message_confirmation: "",
            message_type_confirmation: "information"
        });

        switch (fn) 
        {
            case "onTransferBusiness":
                await this.onTransferBusiness();
                break;

            case "onSendingMessageWhatsAppOutside":
                await this.onSendingMessageWhatsAppOutside();
                break;
        
            default:
                break;
        }
    }

    onClickNoConfirmation = async () =>
    {
        await this.setState({
            statusConfirmation: false,
            objectConfirmation: {},
            message_show_confirmation: false,
            message_confirmation: "",
            showFieldAttendantSelect: false,
            message_type_confirmation: "information",
            showFieldSendigMessage: false,
            fieldMessageWhatsApp: ""
        });
    }

    render() 
    {
        return (
            <div className="business">
                <Header title="Negócios" classaditional="headerBusiness"/>
                <Message
                    message_type={this.state.message_type}
                    message={this.state.message}
                    onClose={() => this.setState({message_show: false})}
                    show={this.state.message_show}
                />

                {
                    this.state.showModalMoreFilters &&
                    <div className="modalMoreFiltersBusiness d-none d-md-block">
                        <div className="row no-gutters lineBodyModalMoreFiltersBusiness pt-2">
                            <div className="col-6 pr-1">
                                <SelectMulti
                                    label="Etapa(s) do Funil"
                                    classaditional="selectStatusHeaderBusiness"
                                    default={{name: getOptionsSelectCheckedFormated(this.state.optionsStepFunnelMoreFilters, "[Todas Etapas]", "etapas")}}
                                    options={this.state.optionsStepFunnelMoreFilters} 
                                    onChange={(optionsStepFunnelMoreFilters) => this.setState({optionsStepFunnelMoreFilters})}
                                />
                            </div>
                            <div className="col-6 pl-1">
                                <SelectMulti
                                    label="Cliente(s)"
                                    classaditional="selectStatusHeaderBusiness"
                                    default={{name: getOptionsSelectCheckedFormated(this.state.optionsClientMoreFilters, "[Todos Clientes]", "clientes")}}
                                    options={this.state.optionsClientMoreFilters} 
                                    onChange={(optionsClientMoreFilters) => this.setState({optionsClientMoreFilters})}
                                    withFieldSearch
                                />
                            </div>
                        </div>
                        {/* <div className="row no-gutters lineBodyModalMoreFiltersBusiness featured">
                            <div className="col-6 pr-1">
                                <SelectMulti
                                    label="Fonte(s)"
                                    classaditional="selectStatusHeaderBusiness"
                                    default={{name: getOptionsSelectCheckedFormated(this.state.optionsSelectFrom, "[Todas Fontes]", "fontes")}}
                                    options={this.state.optionsSelectFrom} 
                                    onChange={(optionsSelectFrom) => this.setState({optionsSelectFrom})}
                                    withFieldSearch
                                />
                            </div>
                            <div className="col-6 pr-1">
                                <SelectMulti
                                    label="Campanha(s)"
                                    classaditional="selectStatusHeaderBusiness"
                                    default={{name: getOptionsSelectCheckedFormated(this.state.optionsCampaignMoreFilters, "[Todas Campanhas]", "campanhas")}}
                                    options={this.state.optionsCampaignMoreFilters} 
                                    onChange={(optionsCampaignMoreFilters) => this.setState({optionsCampaignMoreFilters})}
                                    withFieldSearch
                                />
                            </div>
                        </div> */}
                        <div className="row no-gutters lineBodyModalMoreFiltersBusiness">
                            <div className="col-6 pr-1">
                                <InputGeneral
                                    label="Negócio Criado Em:"
                                    type="date" 
                                    onChange={(e) => this.setState({dateCreateStartMoreFilters: e.target.value})}
                                    value={this.state.dateCreateStartMoreFilters}
                                />
                            </div>
                            <div className="col-6 pl-1">
                                <InputGeneral
                                    label="&nbsp;"
                                    type="date" 
                                    onChange={(e) => this.setState({dateCreateEndsMoreFilters: e.target.value})}
                                    value={this.state.dateCreateEndsMoreFilters}
                                />
                            </div>
                        </div>
                        <div className="row no-gutters lineBodyModalMoreFiltersBusiness featured">
                            <div className="col-6 pr-1">
                                <InputGeneral
                                    label="Negócio Fechado Em:"
                                    type="date" 
                                    onChange={(e) => this.setState({dateClosedStartMoreFilters: e.target.value})}
                                    value={this.state.dateClosedStartMoreFilters}
                                />
                            </div>
                            <div className="col-6 pl-1">
                                <InputGeneral
                                    label="&nbsp;"
                                    type="date" 
                                    onChange={(e) => this.setState({dateClosedEndsMoreFilters: e.target.value})}
                                    value={this.state.dateClosedEndsMoreFilters}
                                />
                            </div>
                        </div>
                        <div className="row no-gutters lineBodyModalMoreFiltersBusiness pt-2">
                            <div className="col-6 pl-1">
                            {
                            this.props.leadOwnersPermissions.toView &&
                                <SelectMulti 
                                    label="Proprietário(s)"
                                    classaditional="selectStatusHeaderBusiness"
                                    default={{name: getOptionsSelectCheckedFormated(this.state.optionsOwnersLead, "[Todos Proprietários]", "proprietários")}}
                                    options={this.state.optionsOwnersLead} 
                                    onChange={(optionsOwnersLead) => {
                                        this.setState({optionsOwnersLead});
                                    }}
                                    withFieldSearch
                                />
                            }
                            </div>
                        </div>
                        <div className="row no-gutters lineFooterModalMoreFiltersBusiness">
                            <Button
                                classaditional="buttonModalMoreFiltersBusiness"
                                name="Limpar"
                                onClick={async () => {
                                    await this.setDefaultMoreFilters();
                                    this.setState({allBusinessUpdated: true});
                                    this.setState({showModalMoreFilters: false});
                                    await this.setState({
                                        modeFilterDateBusiness: 2
                                    });
                                }}
                            />
                            <Button
                                classaditional="buttonModalMoreFiltersBusiness positive"
                                name="Aplicar"
                                onClick={async () => {
                                    this.setState({allBusinessUpdated: true});
                                    this.setState({showModalMoreFilters: false});
                                    await this.setState({
                                        modeFilterDateBusiness: 2
                                    });
                                }}
                            />                  
                        </div>
                    </div>
                }
                <div className="warningBusiness d-flex d-md-none">
                    <span>Opsssss, alguns recursos foram desativados para dispositivos móveis.</span>
                </div>
                {
                    this.state.modeBusiness !== "details" &&
                    <div className="containerNavigationHeaderBusiness">
                        <ButtonIcon
                            icon="fad fa-grip-vertical"
                            classaditional={`buttonNavigationHeaderBusiness ${(this.state.modeBusiness === modeBusinessDraggable ? "active" : "")}`}
                            onClick={() => this.props.changeModeViewBusiness(modeBusinessDraggable)}
                        />
                        <ButtonIcon
                            icon="far fa-bars"
                            classaditional={`buttonNavigationHeaderBusiness ${(this.state.modeBusiness === modeBusinessList ? "active" : "")}`}
                            onClick={() => {
                                this.applyFilters();
                                this.props.changeModeViewBusiness(modeBusinessList)
                            }}
                        />
                        {
                            (((!this.state.hasOneDraggableChecked) || (this.state.modeBusiness === modeBusinessDraggable)) && this.props.permissions.toInsert) &&
                            <Button
                                icon="fal fa-plus"
                                name="&nbsp;&nbsp;Negócio"
                                classaditional="buttonPlusNavigationHeaderBusiness"
                                onClick={() => this.newDeal(0)}
                            />
                        }
                        {/* {
                            (this.state.hasOneDraggableChecked && this.state.modeBusiness !== modeBusinessDraggable)&&
                            <ButtonIcon
                                icon="fas fa-trash"
                                classaditional="buttonTrashNavigationHeaderBusiness"
                                onClick={() =>  this.message("information", "nothing...")}
                            />
                        } */}
                        {
                            (this.state.hasOneDraggableChecked && this.state.modeBusiness !== modeBusinessDraggable && this.props.permissions.toUpdate)&&
                            <ButtonIcon
                                title="Transferir"
                                icon="fas fa-exchange-alt"
                                classaditional="buttonTrashNavigationHeaderBusiness"
                                onClick={async () => {
                                    if (this.state.optionsChosenStatusBusiness.value !== "OPEN")
                                    {
                                        return this.message("error", "Só é possível transferir negócios em andamento!");
                                    }

                                    await this.setState({
                                        objectConfirmation: {},
                                        nameFunctionYesModalConfirmation: "onTransferBusiness",
                                        showFieldAttendantSelect: true
                                    });

                                    await this.onTransferBusiness();
                                }}
                            />
                        }
                        <MessageConfirmation
                            message_type={this.state.message_type_confirmation}
                            message={this.state.message_confirmation}
                            show={this.state.message_show_confirmation}
                            onClose={this.onClickNoConfirmation}
                            onClickYes={this.onClickYesConfirmation}
                            showButtonYes={this.state.showFieldSendigMessage ? false : this.props.permissions.toUpdate}
                        >
                                {
                                    this.state.showFieldAttendantSelect &&
                                    <div>
                                        <Select
                                            classaditional="selectAttendantToTransferBusiness"
                                            default={this.state.optionChosenProspectionOwner}
                                            options={this.state.optionsProspectionOwnersTransfer} 
                                            onChange={(optionChosenProspectionOwner) => this.setState({optionChosenProspectionOwner})}
                                            withFieldSearch
                                        />
                                        <br />
                                    </div>
                                }
                                {
                                    this.state.showFieldSendigMessage &&
                                    <div>
                                        <textarea
                                            className="fieldMessageWhatsAppDetailsBusiness"
                                            placeholder="...mensagem" 
                                            rows="5"
                                            onChange={(e) => this.setState({fieldMessageWhatsApp: e.target.value})}
                                        >
                                        </textarea>
                                        <br />
                                        <div className="lineMessagesWhatsAppBusiness">
                                            <Button
                                                classaditional="buttonWhatsAppBusiness information"
                                                name="ver mensagens"
                                                onClick={this.seeMessagesWhatsApp}
                                            />
                                            <Button
                                                classaditional="buttonWhatsAppBusiness"
                                                name="enviar"
                                                onClick={this.onClickYesConfirmation}
                                            />
                                        </div>
                                    </div>
                                }
                        </MessageConfirmation>
                        <div className={`filterStatusHeaderBusiness d-none d-lg-flex ml-auto p-0`}>
                            <Button
                                name="Última semana"
                                classaditional={`buttonFilterDateBusiness ${this.state.modeFilterDateBusiness === 1 && "active"}`}
                                onClick={async () => {
                                    await this.setState({
                                        modeFilterDateBusiness: 1
                                    });
                                    await this.setInitialAndFinalDateFromWeek();
                                    await this.applyFilters();
                                }}
                            />
                            <Button
                                name="Todos"
                                classaditional={`buttonFilterDateBusiness ${this.state.modeFilterDateBusiness === 2 && "active"}`}
                                onClick={async () => {
                                    await this.setState({
                                        modeFilterDateBusiness: 2,
                                        dateCreateStartMoreFilters: null,
                                        dateCreateEndsMoreFilters: null
                                    });
                                    await this.applyFilters();
                                }}
                            />
                        </div>
                        <div className={`filterStatusHeaderBusiness d-none ml-2 d-lg-flex`}>
                            <i className="fas fa-building iconfilterStatusHeaderBusiness"></i>
                            <SelectMulti
                                classaditional="selectStatusHeaderBusiness"
                                default={{name: getOptionsSelectCheckedFormated(this.state.optionsUnitCompany, "[Todas Unidades]", "unidades")}}
                                options={this.state.optionsUnitCompany} 
                                onChange={async (optionsUnitCompany) => {
                                    await this.getOwners();
                                    this.setState({optionsUnitCompany});
                                    this.setState({allBusinessUpdated: true});
                                }}
                                withFieldSearch
                            />
                        </div>
                        <div className="filterStatusHeaderBusiness d-none ml-2 d-lg-flex">
                            <i className="fas fa-user-friends iconfilterStatusHeaderBusiness"></i>
                            <SelectMulti
                                classaditional="selectStatusHeaderBusiness selectAttendantsHeaderBusiness"
                                default={{name: getOptionsSelectCheckedFormated(this.state.optionsAttendants, "[Todos Atendentes]", "atendentes")}}
                                options={this.state.optionsAttendants} 
                                onChange={(optionsAttendants) => {
                                    this.setState({optionsAttendants});
                                    this.setState({allBusinessUpdated: true});
                                }}
                                withFieldSearch
                            />
                        </div>
                        <div className="filterStatusHeaderBusiness d-none d-md-flex ml-2">
                            <i className="fas fa-info iconfilterStatusHeaderBusiness"></i>
                            <Select
                                classaditional="selectStatusHeaderBusiness"
                                default={this.state.optionsChosenStatusBusiness}
                                options={this.state.optionsSelectStatus} 
                                onChange={async (optionsChosenStatusBusiness) => {
                                    await this.setState({allBusinessUpdated: true, optionsChosenStatusBusiness});
                                }}
                            />
                        </div>
                        <Button
                            icon="fal fa-plus"
                            name="&nbsp;&nbsp;Filtros"
                            classaditional="buttonPlusNavigationHeaderBusiness"
                            onClick={() => this.setState({showModalMoreFilters: !this.state.showModalMoreFilters})}
                        />
                    </div>
                }

                {
                    this.state.modeBusiness === modeBusinessDraggable &&
                    <Fragment>
                        <div className="containerDraggableAreaHeaderBusiness d-none d-md-flex"> 
                            <div className="colDraggableAreaHeaderBusines"> 
                                <h5 className="titleColumnBusiness">Lead</h5>
                                <p className="informationColumnBusiness">
                                    R$ { formatedMoneyBRA(this.state.headerSummary[0].money.toString()) } - { this.state.headerSummary[0].count } negócio(s)
                                </p>

                                <svg className="arrow" width="16" height="56" xmlns="http://www.w3.org/2000/svg"><g  fillRule="evenodd"><path className="arrow__right" fill="#f7f7f7" d="M0 0h16v56H0z"></path><path className="arrow__border" fill="#E5E5E5" d="M1 0l8 28-8 28H0V0z"></path><path className="arrow__left" fill="#f7f7f7" d="M0 1l8 27-8 27z"></path></g></svg>
                            </div>
                            <div className="colDraggableAreaHeaderBusines">
                                <h5 className="titleColumnBusiness">Primeiro Contato</h5>
                                <p className="informationColumnBusiness">
                                    R$ { formatedMoneyBRA(this.state.headerSummary[1].money.toString()) } - { this.state.headerSummary[1].count } negócio(s)
                                </p>
                                <svg className="arrow" width="16" height="56" xmlns="http://www.w3.org/2000/svg"><g  fillRule="evenodd"><path className="arrow__right" fill="#f7f7f7" d="M0 0h16v56H0z"></path><path className="arrow__border" fill="#E5E5E5" d="M1 0l8 28-8 28H0V0z"></path><path className="arrow__left" fill="#f7f7f7" d="M0 1l8 27-8 27z"></path></g></svg>
                                
                            </div>
                            <div className="colDraggableAreaHeaderBusines">
                                <h5 className="titleColumnBusiness">Atendimento Presencial</h5>
                                <p className="informationColumnBusiness">
                                    R$ { formatedMoneyBRA(this.state.headerSummary[2].money.toString()) } - { this.state.headerSummary[2].count } negócio(s)
                                </p>
                                <svg className="arrow" width="16" height="56" xmlns="http://www.w3.org/2000/svg"><g  fillRule="evenodd"><path className="arrow__right" fill="#f7f7f7" d="M0 0h16v56H0z"></path><path className="arrow__border" fill="#E5E5E5" d="M1 0l8 28-8 28H0V0z"></path><path className="arrow__left" fill="#f7f7f7" d="M0 1l8 27-8 27z"></path></g></svg>
                                
                            </div>
                            <div className="colDraggableAreaHeaderBusines">
                                <h5 className="titleColumnBusiness">Fechamento</h5>
                                <p className="informationColumnBusiness">
                                    R$ { formatedMoneyBRA(this.state.headerSummary[3].money.toString()) } - { this.state.headerSummary[3].count } negócio(s)
                                </p>
                                <svg className="arrow" width="16" height="56" xmlns="http://www.w3.org/2000/svg"><g  fillRule="evenodd"><path className="arrow__right" fill="#f7f7f7" d="M0 0h16v56H0z"></path><path className="arrow__border" fill="#E5E5E5" d="M1 0l8 28-8 28H0V0z"></path><path className="arrow__left" fill="#f7f7f7" d="M0 1l8 27-8 27z"></path></g></svg>
                                
                            </div>
                            <div className="colDraggableAreaHeaderBusines">
                                <h5 className="titleColumnBusiness">Reagendar</h5>
                                <p className="informationColumnBusiness">
                                    R$ { formatedMoneyBRA(this.state.headerSummary[4].money.toString()) } - { this.state.headerSummary[4].count } negócio(s)
                                </p>
                            </div>
                        </div>
                        <div className="containerDraggableAreBusiness d-none d-md-flex" ref={this.containerDraggable} >
                            <div className={`columnDraggableAreaBusiness ${this.state.activeColumn === 0 ? "active" : ""}`} ref={this.columnDraggable}>
                                {this.loadAllDraggablesByColumn(0)}
                                { 
                                    this.props.permissions.toInsert && 
                                    <Button
                                        icon="fal fa-plus"
                                        classaditional="buttonPlusDealDraggableBusiness"
                                        name=""
                                        onClick={() => this.newDeal(0)}
                                        style={{width: this.state.widthColumn - 20}}
                                    />
                                }
                            </div>
                            <div className={`columnDraggableAreaBusiness ${this.state.activeColumn === 1 ? "active" : ""}`} >
                                {this.loadAllDraggablesByColumn(1)}
                                {
                                    this.props.permissions.toInsert &&
                                    <Button
                                        icon="fal fa-plus"
                                        classaditional="buttonPlusDealDraggableBusiness"
                                        name=""
                                        onClick={() => this.newDeal(1)}
                                        style={{width: this.state.widthColumn - 20}}
                                    />
                                }
                            </div>
                            <div className={`columnDraggableAreaBusiness ${this.state.activeColumn === 2 ? "active" : ""}`} >
                                {this.loadAllDraggablesByColumn(2)}
                                {
                                    this.props.permissions.toInsert &&
                                    <Button
                                        icon="fal fa-plus"
                                        classaditional="buttonPlusDealDraggableBusiness"
                                        name=""
                                        onClick={() => this.newDeal(2)}
                                        style={{width: this.state.widthColumn - 20}}
                                    />
                                }
                            </div>
                            <div className={`columnDraggableAreaBusiness ${this.state.activeColumn === 3 ? "active" : ""}`} >
                                {this.loadAllDraggablesByColumn(3)}
                                {
                                    this.props.permissions.toInsert &&
                                    <Button
                                        icon="fal fa-plus"
                                        classaditional="buttonPlusDealDraggableBusiness"
                                        name=""
                                        onClick={() => this.newDeal(3)}
                                        style={{width: this.state.widthColumn - 20}}
                                    />
                                }
                            </div>
                            <div className={`columnDraggableAreaBusiness ${this.state.activeColumn === 4 ? "active" : ""}`} >
                                {this.loadAllDraggablesByColumn(4)}
                                {
                                    this.props.permissions.toInsert &&
                                    <Button
                                        icon="fal fa-plus"
                                        classaditional="buttonPlusDealDraggableBusiness"
                                        name=""
                                        onClick={() => this.newDeal(4)}
                                        style={{width: this.state.widthColumn - 20}}
                                    />
                                }
                            </div>

                            {/* RESERVED */}
                            {/* <Draggable 
                                onDrag={(data) => this.onDrag(data)}
                                onStart={(data) => this.onStart(data)}
                                onStop={(data) => this.onStop()}
                                position={{x: this.state.x, y: this.state.y}}
                                width={this.props.widthColumn}
                            /> */}
                        </div>
                        <div className={`containerDraggableAreaFooterBusiness ${(this.state.activeFooter === "active") ? "active" : ""}`}>
                            <div className="columnDraggableAreaFooterBusiness">
                                <Button
                                    classaditional="buttonDraggableFooterBusiness" 
                                    name="excluir"
                                    onMouseOver={() => this.setState({actionTypeFooterDraggable: "delete"})}
                                    onMouseLeave={() => this.setState({actionTypeFooterDraggable: ""})}
                                />
                            </div>
                            <div className="columnDraggableAreaFooterBusiness">
                                <Button
                                    classaditional="buttonDraggableFooterBusiness error" 
                                    name="perdido"
                                    onMouseOver={() => this.setState({actionTypeFooterDraggable: "lost"})}
                                    onMouseLeave={() => this.setState({actionTypeFooterDraggable: ""})}
                                />
                            </div>
                            <div className="columnDraggableAreaFooterBusiness">
                                <Button
                                    classaditional="buttonDraggableFooterBusiness success" 
                                    name="ganho"
                                    onMouseOver={() => this.setState({actionTypeFooterDraggable: "gain"})}
                                    onMouseLeave={() => this.setState({actionTypeFooterDraggable: ""})}
                                />
                            </div>
                            <div className="columnDraggableAreaFooterBusiness">
                                <Button
                                    classaditional="buttonDraggableFooterBusiness" 
                                    name="mover/converter"
                                    onMouseOver={() => this.setState({actionTypeFooterDraggable: "move"})}
                                    onMouseLeave={() => this.setState({actionTypeFooterDraggable: ""})}
                                />
                            </div>
                        </div>
                    </Fragment>
                }

                {
                    this.state.modeBusiness === modeBusinessList &&
                    <Fragment>
                        <div className="containerListHeaderBusiness">
                            <TableEditBusiness
                                data={this.state.allDraggables}
                                allDraggablesChecked={this.state.allDraggablesChecked}
                                onToggleCheckDraggable={async (status) => {
                                    await this.props.toggleCheckedAllBusiness(status);
                                    await this.hasAllDraggablesChecked();
                                }}
                                onChangeValue={this.changeValue}
                                onSaveValue={this.saveValue}
                                onIgnoreSave={async () => {
                                    await this.props.changeStatusGetFromDatabaseCompletedBusiness(false);
                                    await this.applyFilters();
                                }}
                                permissions={this.props.allPermissions}
                                optionsOwners={this.state.optionOwnerSpecialistOrManagers}
                                optionsProspectionOwnersTransfer={this.state.optionsProspectionOwnersTransfer}
                                hasAllDraggablesChecked={this.hasAllDraggablesChecked}
                            />
                        </div>
                    </Fragment>
                }
            </div>          
        );
    }
}

function mapStateToProps (state)
{
    const {newDeal, changeDeal, modeBusinesFinal, allDraggables} = state.business;
    const { allUsers } = state.leads;
    const { user } = state.auth;
    const leadOwnersPermissions = state.permissions.leadOwners;
    const permissions = state.permissions.business;
    const whatsapp = state.permissions.whatsapp;
    const allPermissions = state.permissions;
    const hasPermissionsLoaded = state.permissions.hasPermissionsLoaded;
    const queue = state.queue;

    return {
        user,
        queue,
        newDeal,
        whatsapp,
        allUsers,
        changeDeal,
        permissions,
        allDraggables,
        allPermissions,
        modeBusinesFinal,
        hasPermissionsLoaded,
        leadOwnersPermissions,
    }
}

function mapDispatchToProps (dispatch)
{
    return {
        showModalMoreActions (data)
        {
            //action creator -> action
            const action = showModalMoreActions(data);
            dispatch(action);
        },
        changeModeViewBusiness (mode) 
        {
            //action creator -> action
            const action = changeModeViewBusiness(mode);
            dispatch(action);
        },
        changeDealBusiness (data) 
        {
            //action creator -> action
            const action = changeDealBusiness(data);
            dispatch(action);
        },
        getAllDraggablesBusiness (data)
        {
            //action creator -> action
            const action = getAllDraggablesBusiness(data);
            dispatch(action);
        },
        getAllUsersLeads (data)
        {
            //action creator -> action
            const action = getAllUsersLeads(data);
            dispatch(action);
        },
        changeStatusGetFromDatabaseCompletedBusiness(data)
        {
            //action creator -> action
            const action = changeStatusGetFromDatabaseCompletedBusiness(data);
            dispatch(action);
        },
        toggleCheckedAllBusiness (status) 
        {
            //action creator -> action
            const action = toggleCheckedAllBusiness(status);
            dispatch(action);
        },
        setLastPositionSpecialistServiceQueue (data) 
        {
            //action creator -> action
            const action = setLastPositionSpecialistServiceQueue(data);
            dispatch(action);
        },
        changeStatusAllSpecialistsUpdatedServiceQueue (status) 
        {
            //action creator -> action
            const action = changeStatusAllSpecialistsUpdatedServiceQueue(status);
            dispatch(action);
        },
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Business));