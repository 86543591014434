import React, { useEffect, useState } from "react";
import InputGeneral from "./InputGeneral";
import OptionSelect from "./OptionSelect";
import "./Select.css";

export default function Select (props)
{
    const [showOptionsSelect, setShowOptionsSelect] = useState(false);
    const [options, setOptions] = useState([{}]);
    const [optionsNoFilterd, setOptionsNoFiltered] = useState([{}]);
    const [searching, setSearching] = useState(false);

    useEffect (() => 
    {
        if (!searching)
        {
            setOptions(props.options);
            setOptionsNoFiltered(props.options);
        }

    }, [props.options, searching, showOptionsSelect])

    function onClickOptionSelect (objChosen)
    {
        setShowOptionsSelect(false);
        props.onChange(objChosen);
    }

    function filterOptionsSelect (term)
    {
        let options = optionsNoFilterd;
        term = term.toString().toLowerCase();

        if (term !== "")
        {
            options = options.filter(o => {
                let name = o.name.toString().toLowerCase();
                if (name.indexOf(term) >= 0)
                {
                    return o;
                }
                return false;
            });
        }

        setOptions(options);
    }

    return (
        <div className={`select ${props?.classaditional}`}>
            {
                props?.label &&
                <label className="labelSelect">
                    {props.label}
                    {(props.mandatory)? " *" : ""}     
                </label>
            }

            <div 
                className={`fieldSelect  ${(props?.valid === "false") ? "error" : null} ${props.disabled ? "disabledSelect" : ""}`}
                onClick={() => setShowOptionsSelect(!showOptionsSelect)}
            >
                <div className={`${(props?.default?.style) ? " lead "+props.default.style : ""}`}>
                    {props?.default?.name }
                </div>
                <div className="iconShowSelect">
                    {
                        showOptionsSelect
                        ? <i className="far fa-chevron-up"></i>
                        : <i className="far fa-chevron-down"></i>
                    }
                    
                </div>
            </div>
            {
                showOptionsSelect &&
                <div className="optionsSelect">
                    {
                        props.withFieldSearch &&
                        <InputGeneral
                            classaditional="p-2"
                            placeholder="pesquise"
                            onChange={async (e) => {
                                await setSearching(true);
                                filterOptionsSelect(e.target.value);
                            }}
                        />
                    }
                    {options.map((e, i) => {
                        return (
                            <OptionSelect 
                                key={i}
                                onClick={(e) => onClickOptionSelect(e)}
                                name={e.name}
                                value={e.value}
                                checked={(props?.default?.value === e.value) ? true : false}
                                classaditional={`${e?.style ? " lead "+e.style : ""}`}
                                style={`${e?.style}`}
                            />
                        )
                    })}
                </div>
            }
        </div>
    )
}
