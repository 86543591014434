import React, { useCallback, useState } from "react";
import "./ScreensCRM.css";
import { Switch, Route, useLocation, Redirect } from "react-router-dom";

import { connect } from "react-redux";
import { useEffect } from "react";
import { setCurrentScreenCRM } from "../../store/actions/general";

import Leads from "./Leads";
import Business from "./Business";
import DetailsBusiness from "./DetailsBusiness";
import AdvancedBusiness from "./AdvancedBusiness";
import Activities from "./Activities";
import Owners from "./Owners";
import DetailsPermissions from "./DetailsPermissions";
import Permissions from "./Permissions";
import PersonalPreferences from "./PersonalPreferences";
import CompanyPreferences from "./CompanyPreferences";
import UnitCompany from "./UnitCompany";
import Dashboard from "./Dashboard";
import CommissionRules from "./CommissionRules";
import CommissionRulesProspection from "./CommissionRulesProspection";
import CommissionRulesIntervals from "./CommissionRulesIntervals";
import CommissionReport from "./CommissionReport";
import CommissionReportAttendant from "./CommissionReportAttendant";
import CommissionReportManager from "./CommissionReportManager";
import CommissionReportSupervisor from "./CommissionReportSupervisor";
import ServiceQueue from "./ServiceQueue";
import Campaign from "./Campaign";
import Development from "./Development";
import ConnectionLogs from "./ConnectionLogs";
import DashboardProspection from "./DashboardProspection";
import DashboardMarketing from "./DashboardMarketing";
import Debug from "../general/Debug";
import Chat from "./Chat";
import Room from "./Room";
import Training from "./Training";
import DetailsService from "./DetailsService";
import Service from "./Service";
import PersonOnline from "./PersonOnline";
import ServiceQueuePreSale from "./ServiceQueuePreSale";
import AbsenceAttendants from "./AbsenceAttendants";
import NotFound from "../general/NotFound";
import Integrations from "./Integrations";
import CommissionReportIntervals from "./CommissionReportIntervals";
import WhatsAppInProgress from "./WhatsAppInProgress";
import WhatsAppInQueue from "./WhatsAppInQueue";

function ScreensCRM (props)
{
    const location = useLocation();
    const [redirectDashboard, setRedirectDashboard] = useState("");

    const getCorrectDashboardOrActivitiesPanelForUserOnAccess = useCallback(() => {

        if (props.hasPermissionsLoaded) 
        {          
            let permissions = props.permissions;
            let dashboards = [];
            let routeNameRedirect = "";
    
            // getting all permissions by every dashboards
            Object.keys(permissions).forEach(k => {
                if (k.toString().includes("dashboard"))
                {
                    let objPermissionsDashboad = permissions[k];
    
                    if (objPermissionsDashboad.toView)
                    {
                        let dashboardName = k.toString().substring(9);
    
                        if (dashboardName !== "" && !dashboardName.includes("Months"))
                        {
                            let dashboardRouteName = `dashboard-${dashboardName.toLowerCase()}`;
                            dashboards.push(dashboardRouteName);
                        }
    
                        if (dashboardName === "") { dashboards.push("dashboard"); }
                    }
                }
            });
    
            // setting the final route name...
            routeNameRedirect = (dashboards.length === 0) ? "activities" : dashboards[0];
    
            setRedirectDashboard(routeNameRedirect);
        }

    }, [props]);

    useEffect(() => { 

        props.setCurrentScreenCRM(location.pathname);        
        getCorrectDashboardOrActivitiesPanelForUserOnAccess();

    }, [location, props, getCorrectDashboardOrActivitiesPanelForUserOnAccess])

    return (
        <main className="screensCRM">
            <Switch>
                <Route path="/leads">
                    <Leads />
                </Route>
                <Route path="/activities">
                    <Activities />
                </Route>
                <Route path="/owners">
                    <Owners />
                </Route>
                <Route path="/campaign">
                    <Campaign />
                </Route>
                <Route path="/development">
                    <Development />
                </Route>
                <Route path="/debug">
                    <Debug />
                </Route>
                <Route path="/room/:room">
                    <Room />
                </Route>
                <Route path="/chat">
                    <Chat />
                </Route>
                <Route path="/connection-logs">
                    <ConnectionLogs />
                </Route>
                <Route path="/training">
                    <Training />
                </Route>
                <Route path="/attendants-absence">
                    <AbsenceAttendants />
                </Route>

                {/* start about whatsapp */}
                <Route path="/whatsapp-queue">
                    <WhatsAppInQueue idUserCRM={12432} />
                </Route>
                <Route path="/whatsapp/:phoneClient?/:nameClient?/:idCompanyUnit?">
                    <WhatsAppInProgress idUserCRM={props.user.PER_ID} />
                </Route>
                {/* ends about whatsapp */}

                {/* start about service queue */}
                <Route path="/service-queue">
                    <ServiceQueue />
                </Route>
                <Route path="/service-queue-pre-sale">
                    <ServiceQueuePreSale />
                </Route>
                {/* ends about service queue */}

                {/* start about user */}
                <Route path="/preferences-user">
                    <PersonalPreferences />
                </Route>
                <Route path="/online-user">
                    <PersonOnline />
                </Route>
                {/* ends about user */}

                {/* start about dashboard */}
                <Route exact path="/">
                    <Redirect to={`/${redirectDashboard}`} />
                </Route>
                <Route exact path="/dashboard">
                    <Dashboard />
                </Route>
                <Route path="/dashboard-prospection">
                    <DashboardProspection />
                </Route>
                <Route path="/dashboard-marketing">
                    <DashboardMarketing />
                </Route>
                {/* ends about dashboard */}

                {/* start about commission */}
                <Route path="/commission-rules">
                    <CommissionRules />
                </Route>
                <Route path="/commission-rules-prospection">
                    <CommissionRulesProspection />
                </Route>
                <Route path="/commission-rules-intervals">
                    <CommissionRulesIntervals />
                </Route>
                <Route path="/commission-report/:interval?">
                    <CommissionReport />
                </Route>
                <Route path="/commission-report-manager">
                    <CommissionReportManager />
                </Route>
                <Route path="/commission-report-attendant/:interval?">
                    <CommissionReportAttendant />
                </Route>
                <Route path="/commission-report-supervisor/:interval?">
                    <CommissionReportSupervisor />
                </Route>
                <Route path="/commission-report-intervals/:interval?">
                    <CommissionReportIntervals />
                </Route>
                {/* ends about commission */}

                {/* start about company */}
                <Route path="/preferences-company">
                    <CompanyPreferences />
                </Route>
                <Route path="/unit-company">
                    <UnitCompany />
                </Route>
                {/* ends about company */}

                {/* start about permissions */}
                <Route exact path="/permissions">
                    <Permissions />
                </Route>                
                <Route path="/permissions/details/:id">
                    <DetailsPermissions />
                </Route>                
                {/* ends about permissions */}

                {/* start about business */}
                <Route exact path="/business">
                    <Business />
                </Route>
                <Route exact path="/business/advanced">
                    <AdvancedBusiness />
                </Route>
                <Route path="/business/details/:id">
                    <DetailsBusiness />
                </Route>
                {/* ends about business */}

                {/* start about services */}
                <Route path="/services/details/:id">
                    <DetailsService />
                </Route>
                <Route path="/service">
                    <Service />
                </Route>
                {/* ends about services */}

                {/* start about integrations */}
                <Route path="/integrations">
                    <Integrations />
                </Route>
                {/* ends about integrations */}

                <Route path="*">
                    <NotFound />
                </Route>
            </Switch>
        </main>
    )
}

function mapStateToProps (state)
{
	const {user} = state.auth;
    const permissions = state.permissions;
	const hasPermissionsLoaded = state.permissions.hasPermissionsLoaded;

    return {
		user,
        permissions,
		hasPermissionsLoaded,
    }
}

function mapDispatchToProps (dispatch)
{
    return {
        setCurrentScreenCRM (path) 
        {
            //action creator -> action
            const action = setCurrentScreenCRM(path);
            dispatch(action);
        },
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ScreensCRM);