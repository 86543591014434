import { SHOW_MODAL_MORE_ACTIONS_GENERAL, SET_MODAL_MESSAGE, SET_CURRENT_SCREEN_CRM, LISTENER_WHATSAPP_UPDATED_MESSAGES } from "../../core/constants";

const initialState = {
    showModal: false,
    typeModal: null,
    modeUpdateModal: false, //false for title as "Inserir" or true for title as "Editar"
    dataModal: null,

    // start modal message
    message_type: "information",
    message_show: false,
    message: "",
    // ends modal message

    listenerWhatsAppUpdatedMessagesActivated: false,
    currentScreenCRM: "",
}

export default function generalReducer (state = initialState, action)
{
    switch (action.type)
    {
        case SHOW_MODAL_MORE_ACTIONS_GENERAL:
            return {
                ...state,
                showModal: action.payload.show,
                typeModal: action.payload.type,
                modeUpdateModal: action.payload?.modeUpdateModal,
                dataModal: action.payload?.data
            }

        case SET_MODAL_MESSAGE:
            return {
                ...state,
                message_show: action.payload.show,
                message_type: action.payload.type,
                message: action.payload.message
            }    

        case SET_CURRENT_SCREEN_CRM:
            return {
                ...state,
                currentScreenCRM: action.payload
            }
 
        case LISTENER_WHATSAPP_UPDATED_MESSAGES:
            return {
                ...state,
                listenerWhatsAppUpdatedMessagesActivated: action.payload
            }

        default:
            return state
    }
}