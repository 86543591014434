import React, { useEffect } from "react";
import Carousel from "react-bootstrap/Carousel";
import { FaArrowLeft, FaArrowRight } from "react-icons/fa";
import "./CustomCarousel.css";

function CustomCarousel({ items }) {
  useEffect(() => {
    const removeElementFromIframe = () => {
      document.querySelectorAll("iframe").forEach((iframe) => {
        try {
          const iframeDocument =
            iframe.contentDocument || iframe.contentWindow.document;
          if (iframeDocument) {
            iframeDocument
              .querySelectorAll('[data-tooltip="Outra janela"]')
              .forEach((element) => element.remove());
          }
        } catch (error) {
          console.error("Erro ao acessar o conteúdo do iframe:", error);
        }
      });
    };

    removeElementFromIframe();

    const observer = new MutationObserver(() => {
      removeElementFromIframe();
    });

    observer.observe(document.body, { childList: true, subtree: true });

    return () => observer.disconnect();
  }, []);

  return (
    <Carousel
      className="containerCustomCarousel"
      prevIcon={items.length > 1 && <FaArrowLeft />}
      nextIcon={items.length > 1 && <FaArrowRight />}
      controls={items.length > 1}
      indicators={items.length > 1}
      interval={5000}
    >
      {items.map((item, index) => (
        <Carousel.Item key={index}>
          {item.type === "image" ? (
            <img
              className="d-block w-100"
              src={item.src}
              alt={item.alt || `Slide ${index + 1}`}
            />
          ) : (
            <iframe
              className="d-block w-100"
              src={item.src}
              title={item.alt || `Slide ${index + 1}`}
              frameBorder="0"
              allowFullScreen
              style={{ height: "800px" }}
              sandbox="allow-scripts allow-same-origin"
            ></iframe>
          )}
          {item.caption && (
            <Carousel.Caption>
              <h3>{item.caption.title}</h3>
              <p>{item.caption.text}</p>
            </Carousel.Caption>
          )}
        </Carousel.Item>
      ))}
    </Carousel>
  );
}

export default CustomCarousel;
