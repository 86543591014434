import API from "../api";
import { apiCallbackTreatment } from "../auxiliary/generalFunctions";

const api = new API();

export default class WhatsAppController
{
    async getAllChats(amount, searching, idUserCRM, idUserCRMOnAccess, toViewAll) 
    {
        const response = await api.whatsapp().getAllChats(amount, searching, idUserCRM, idUserCRMOnAccess, toViewAll);
        return apiCallbackTreatment(response);
    }

    async send({idContact, message, phone, idUserCRM, nameUserCRM, idDepartament, idCalled, idUserCRMFromCalled, idDepartamentFromUserWithMultipleDepartaments, idMessageReference = null, file = null}) 
    {
        const response = await api.whatsapp().send(idContact, message, phone, idUserCRM, nameUserCRM, idDepartament, idCalled, idUserCRMFromCalled, idDepartamentFromUserWithMultipleDepartaments, idMessageReference, file);
        return apiCallbackTreatment(response);
    }

    async sendImage(formData) 
    {
        const response = await api.whatsapp().sendImage(formData);
        return apiCallbackTreatment(response);
    }

    async sendDocument(formData) 
    {
        const response = await api.whatsapp().sendDocument(formData);
        return apiCallbackTreatment(response);
    }

    async sendAudio(formData) 
    {
        const response = await api.whatsapp().sendAudio(formData);
        return apiCallbackTreatment(response);
    }
    
    async deleteMessage(id, idDepartament) 
    {
        const response = await api.whatsapp().deleteMessage(id, idDepartament);
        return apiCallbackTreatment(response);
    }

    async getAmountScreenshotToAnswerMessage(id)
    {
        const response = await api.whatsapp().getAmountScreenshotToAnswerMessage(id);
        return apiCallbackTreatment(response);
    }

    async getTokenFirebase()
    {
        const response = await api.whatsapp().getTokenFirebase();
        return apiCallbackTreatment(response);
    }

    async reTryingDownloadFile (id, idDepartament)
    {
        const response = await api.whatsapp().reTryingDownloadFile(id, idDepartament);
        return apiCallbackTreatment(response);
    }

    async setAudioDuration (idMessageDigiSac, duration)
    {
        const response = await api.whatsapp().setAudioDuration(idMessageDigiSac, duration);
        return apiCallbackTreatment(response);
    }

    async closeCalled({idUserCRMFromCalled, nameUserCRMFromCalled, idCalled}) 
    {
        const response = await api.whatsapp().closeCalled(idUserCRMFromCalled, nameUserCRMFromCalled, idCalled);
        return apiCallbackTreatment(response);
    }

    async updateNotReadCalledsAndMessages(idUserCRMFromCalled, idCalled) 
    {
        const response = await api.whatsapp().updateNotReadCalledsAndMessages(idUserCRMFromCalled, idCalled);
        return apiCallbackTreatment(response);
    }

    async getAllDepartaments() 
    {
        const response = await api.whatsapp().getAllDepartaments();
        return apiCallbackTreatment(response);
    }

    async transferCalled(idContact, cellphoneNumberContact, idUserCRMFromCalled, idCalled, idDepartamentDestiny) 
    {
        const response = await api.whatsapp().transferCalled(idContact, cellphoneNumberContact, idUserCRMFromCalled, idCalled, idDepartamentDestiny);
        return apiCallbackTreatment(response);
    }

    async checkingUserWithMultipleDepartaments(id) 
    {
        const response = await api.whatsapp().checkingUserWithMultipleDepartaments(id);
        return apiCallbackTreatment(response);
    }

    async getWichDepartamentsSlugsByUser(id) 
    {
        const response = await api.whatsapp().getWichDepartamentsSlugsByUser(id);
        return apiCallbackTreatment(response);
    }

    async setDepartamentsByUser(idUserCRM, slugsDepartament, idsCompanyUnits) 
    {
        const response = await api.whatsapp().setDepartamentsByUser(idUserCRM, slugsDepartament, idsCompanyUnits);
        return apiCallbackTreatment(response);
    }

    async getMessagesByChat(idCalled, idDepartament, idUserCRMOnAccess, idContact, toViewAll, amountBeforeCalleds, activeFilterByParamsURL) 
    {
        const response = await api.whatsapp().getMessagesByChat(idCalled, idDepartament, idUserCRMOnAccess, idContact, toViewAll, amountBeforeCalleds, activeFilterByParamsURL);
        return apiCallbackTreatment(response);
    }

    async sendMessageOutside(fields) 
    {
        const response = await api.whatsapp().sendMessageOutside(fields);
        return apiCallbackTreatment(response);
    }

    async getMessagesByClosedCalled(fields) 
    {
        const response = await api.whatsapp().getMessagesByClosedCalled(fields);
        return apiCallbackTreatment(response);
    }
}