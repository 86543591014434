import React, { useState, useEffect } from "react";

const Timer = ({ token, initialMinutes, onTimeEnd }) => {
    const [timeRemaining, setTimeRemaining] = useState(() => {
        const storedData = localStorage.getItem(`timer-${token}`);
        if (storedData)
        {
            const { endTime } = JSON.parse(storedData);
            const remaining = Math.max(0, Math.floor((new Date(endTime) - new Date()) / 1000));
            return remaining;
        }
        const endTime = new Date(new Date().getTime() + initialMinutes * 60 * 1000);
        localStorage.setItem(
            `timer-${token}`,
            JSON.stringify({ endTime: endTime.toISOString(), token })
        );
        return initialMinutes * 60;
    });

    const [hasEnded, setHasEnded] = useState(false);

    useEffect(() => {
        const interval = setInterval(() => {
            setTimeRemaining((prev) => {
                const newRemaining = prev - 1;
                if (newRemaining <= 0)
                {
                    clearInterval(interval);
                    if (!hasEnded && onTimeEnd)
                    {
                        onTimeEnd();
                        setHasEnded(true);
                    }
                }
                return Math.max(0, newRemaining);
            });
        }, 1000);

        return () => clearInterval(interval);
    }, [hasEnded, onTimeEnd]);

    const formatTime = (seconds) => {
        const mins = Math.floor(seconds / 60);
        const secs = seconds % 60;
        return `${String(mins).padStart(2, "0")}:${String(secs).padStart(2, "0")}`;
    };

    return <span>{formatTime(timeRemaining)}</span>;
};

export default Timer;
