import API_ERP from "../api/erp";
import { apiCallbackTreatment } from "../auxiliary/generalFunctions";

const apiERP = new API_ERP();

export default class ServiceController 
{
    async create(data) 
    {
        const response = await apiERP.services().post(data);
        return apiCallbackTreatment(response);
    }

    async getById(id) 
    {
        const response = await apiERP.services().getById(id);
        return apiCallbackTreatment(response);
    }

    async getClientByNationalRegistration(nationalRegistration) 
    {
        const response = await apiERP.services().getClientByNationalRegistration(nationalRegistration);
        return apiCallbackTreatment(response);
    }
    
    async getClientById(id) 
    {
        const response = await apiERP.services().getClientById(id);
        return apiCallbackTreatment(response);
    }

    async getCitiesFromState(state) 
    {
        const response = await apiERP.services().getCitiesFromState(state);
        return apiCallbackTreatment(response);
    }

    async getNeighborhoodFromCity(city) 
    {
        const response = await apiERP.services().getNeighborhoodFromCity(city);
        return apiCallbackTreatment(response);
    }

    async getAddressOptionsFromText(stateAbbreviation, cityName, neighborhoodName) 
    {
        const response = await apiERP.services().getAddressOptionsFromText(stateAbbreviation, cityName, neighborhoodName);
        return apiCallbackTreatment(response);
    }

    async getOptions()
    {
        const response = await apiERP.services().getOptions();
        return apiCallbackTreatment(response);
    }

    async update(id, data) 
    {
        const response = await apiERP.services().update(id, data);
        return apiCallbackTreatment(response);
    }

    async getWitnesses(officeId, serviceId) 
    {
        const response = await apiERP.services().getWitnesses(officeId, serviceId);
        return apiCallbackTreatment(response);
    }

    async getProcurators(clientId, guarantorId) 
    {
        const response = await apiERP.services().getProcurators(clientId, guarantorId);
        return apiCallbackTreatment(response);
    }

    async getSuccessStories(serviceId) 
    {
        const response = await apiERP.services().getSuccessStories(serviceId);
        return apiCallbackTreatment(response);
    }
    
    async createEnvelope(data) 
    {
        const response = await apiERP.services().createEnvelope(data);
        return apiCallbackTreatment(response);
    }
    
    async checksContractsByClient(clientId) 
    {
        const response = await apiERP.services().checksContractsByClient(clientId);
        return apiCallbackTreatment(response);
    }

    async queryEnvelope(data) 
    {
        const response = await apiERP.services().queryEnvelope(data);
        return apiCallbackTreatment(response);
    }

    async totalSignedEnvelope(data) 
    {
        const response = await apiERP.services().totalSignedEnvelope(data);
        return apiCallbackTreatment(response);
    }

    async detailsEnvelope(data) 
    {
        const response = await apiERP.services().detailsEnvelope(data);
        return apiCallbackTreatment(response);
    }

    async havePendingIssuesOnService(data) 
    {
        const response = await apiERP.services().havePendingIssuesOnService(data);
        return apiCallbackTreatment(response);
    }
    
    async statusSignatures(data) 
    {
        const response = await apiERP.services().statusSignatures(data);
        return apiCallbackTreatment(response);
    }
    
    async createInstallments(idService, data) 
    {
        const response = await apiERP.services().createInstallments(idService, data);
        return apiCallbackTreatment(response);
    }

    async deleteInstallments(idService) 
    {
        const response = await apiERP.services().deleteInstallments(idService);
        return apiCallbackTreatment(response);
    }
    
    async hasInstallments(idService) 
    {
        const response = await apiERP.services().hasInstallments(idService);
        return apiCallbackTreatment(response);
    }

    async createValidationCode(data) 
    {
        const response = await apiERP.services().createValidationCode(data);
        return apiCallbackTreatment(response);
    }

    async queryValidationCode(data) 
    {
        const response = await apiERP.services().queryValidationCode(data);
        return apiCallbackTreatment(response);
    }

    async queryPhoneWhatsapp(data) 
    {
        const response = await apiERP.services().queryPhoneWhatsapp(data);
        return apiCallbackTreatment(response);
    }

    async verifyTokenValidationCode(data) 
    {
        const response = await apiERP.services().verifyTokenValidationCode(data);
        return apiCallbackTreatment(response);
    }

    async releaseContractWithoutWhatsapp(data) 
    {
        const response = await apiERP.services().releaseContractWithoutWhatsapp(data);
        return apiCallbackTreatment(response);
    }

    async sendEnvelope(officeId, serviceId) 
    {
        const response = await apiERP.services().sendEnvelope(officeId, serviceId);
        return apiCallbackTreatment(response);
    }

    async addServicesEnvelope(data) 
    {
        const response = await apiERP.services().addServicesEnvelope(data);
        return apiCallbackTreatment(response);
    }

    async deleteEnvelope(data) 
    {
        const response = await apiERP.services().deleteEnvelope(data);
        return apiCallbackTreatment(response);
    }

    async sendSignEnvelope(data) 
    {
        const response = await apiERP.services().sendSignEnvelope(data);
        return apiCallbackTreatment(response);
    }

    async resendSubscription(data) 
    {
        const response = await apiERP.services().resendSubscription(data);
        return apiCallbackTreatment(response);
    }

    async sendAttachmantService(officeId, serviceId) 
    {
        const response = await apiERP.services().sendAttachmantService(officeId, serviceId);
        return apiCallbackTreatment(response);
    }

    async sendAttachmantEnvelope(data) 
    {
        const response = await apiERP.services().sendAttachmantEnvelope(data);
        return apiCallbackTreatment(response);
    }

    async statusSignaturesEnvelope(officeId, serviceId) 
    {
        const response = await apiERP.services().statusSignaturesEnvelope(officeId, serviceId);
        return apiCallbackTreatment(response);
    }
}